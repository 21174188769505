import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";

import Home from './pages/home.js';
// import Dashboard from './pages/dashboard';

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    // { path: "dashboard", element: <Dashboard /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <App />
    </Router>
  );
};

export default AppWrapper;
import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import $ from "jquery";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

export default function Navbar(props) {
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      10 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      10 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      0 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }
  

    //  buttonanimation starts
    useEffect(() => {
      buttonanimation();
    }, []);
  
    const buttonanimation = async () => {
      let button = document.querySelector(".primary_btn");
      let item = document.querySelector(".primary_btn .round");
  
      button.addEventListener("mouseenter", function (event) {
        this.classList += " animate";
  
        let buttonX = event.offsetX;
        let buttonY = event.offsetY;
  
        if (buttonY < 24) {
          item.style.top = 0 + "px";
        } else if (buttonY > 30) {
          item.style.top = 48 + "px";
        }
  
        item.style.left = buttonX + "px";
        item.style.width = "1px";
        item.style.height = "1px";
      });
  
      button.addEventListener("mouseleave", function (event) {
        this.classList.remove("animate");
  
        let buttonX = event.offsetX;
        let buttonY = event.offsetY;
  
        if (buttonY < 24) {
          item.style.top = 0 + "px";
        } else if (buttonY > 30) {
          item.style.top = 48 + "px";
        }
        item.style.left = buttonX + "px";
      });
    };
    //  buttonanimation ends
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-xxl main_navbar navbar-sticky">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            {/* <span className="navbar-toggler-icon"></span> */}
            <i class="fas fa-bars"></i>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* <div className="dropdown me-3">
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    0x75cf28f29266a413ec878ce5c4a1c9682c819586
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label>BUSD Balance:</label>
                        <span className="ms-2">250.08 </span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Dashboard
                      </a>
                    </li>
                  </ul>
                </div> */}
                <ul>
                  <li>
                    <Link
                      to="home"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="how"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      How To Buy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="economics"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Tokenomics
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="map"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      RoadMap
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="faq"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      BUY NOW
                      <span className="round"></span>
                    </button>
                  </li>
                  {/* <li><button className="primary_btn" data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">PoopMap</button></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h2>BUY Tokens</h2>
              <div className="buy_token_modal_wrap">
                <a
                  className="buy_token_modal_single"
                  href="https://app.uniswap.org/"
                >
                  <img
                    src={require("../assets/images/uniswap_token.png")}
                    className="img-fluid"
                    alt="logo"
                  />
                  <h3>Buy On Uniswap and Get 10% Extra Tokens !!</h3>
                </a>
                <a
                  className="buy_token_modal_single"
                  href="https://pancakeswap.finance/?chain=bscTestnet"
                  target="_blank"
                >
                  <img
                    src={require("../assets/images/pancake_token.png")}
                    className="img-fluid"
                    alt="logo"
                  />
                  <h3>Buy On PanCakeSwap and Get 10% Extra Tokens !!</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Link } from "react-scroll";
export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_top">
            <div className="row">
              <div className="col-md-6">
                <p className="bold" >Disclaimer</p>
                <p className="para">FROGY is a meme token with no intrinsic value or expectation of financial return.</p>
                <p className="para">There is no formal team or whitepaper or roadmap.</p>
                <p className="para">The token is completely useless and for entertainment purposes only.</p>
              </div>
              <div className="col-md-6">
              <ul>
             <li>
              <a href="#">
              <img src={require("../assets/images/h1.png")} alt="img" className="img-fluid" />
              </a>
            </li>
            <li>
              <a href="#">
              <img src={require("../assets/images/h2.png")} alt="img" className="img-fluid" />
              </a>
            </li>
            <li>
              <a href="#">
              <img src={require("../assets/images/h3.png")} alt="img" className="img-fluid" />
              </a>
            </li>
             </ul>
              </div>
            </div>
              
         
          </div>
          <div className="footer_panel_blw">
            <p className="pb-3 mb-0">
              © {new Date().getFullYear()}  - 2033 by FROGY. All rights reserved!
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

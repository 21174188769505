import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Countdown, { zeroPad } from "react-countdown";
import { Link } from "react-scroll";

// import { GetFaq } from "../../src/actions/user";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  // const [faq, setfaq] = useState();

  useEffect(() => {
    AOS.init();
  }, []);

  // useEffect(() => {
  //   Faq();
  // }, []);

  // const Faq = async () => {
  //   let { result } = await GetFaq();
  //   if (result) {
  //     console.log(result, "resultresult");
  //     setfaq(result);
  //   }
  // };

  //  buttonanimation starts
  useEffect(() => {
    buttonanimation();
  }, []);

  const buttonanimation = async () => {
    let button = document.querySelector(".primary_btn");
    let item = document.querySelector(".primary_btn .round");

    button.addEventListener("mouseenter", function (event) {
      this.classList += " animate";

      let buttonX = event.offsetX;
      let buttonY = event.offsetY;

      if (buttonY < 24) {
        item.style.top = 0 + "px";
      } else if (buttonY > 30) {
        item.style.top = 48 + "px";
      }

      item.style.left = buttonX + "px";
      item.style.width = "1px";
      item.style.height = "1px";
    });

    button.addEventListener("mouseleave", function (event) {
      this.classList.remove("animate");

      let buttonX = event.offsetX;
      let buttonY = event.offsetY;

      if (buttonY < 24) {
        item.style.top = 0 + "px";
      } else if (buttonY > 30) {
        item.style.top = 48 + "px";
      }
      item.style.left = buttonX + "px";
    });
  };
  //  buttonanimation ends

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div
        className="page_header"
        data-aos="fade-up"
        data-aos-duration="1000"
        name="home"
      >
        <div className="banner">
          <div className="container">
            <div className="row ban">
              <div
                className="col-xl-5"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1>
                  Cutest <span> Frogy</span> in the town Now, <span>Moon</span>{" "}
                  Is Not Far Away !!
                </h1>

                <div className="h2box">
                  <h2 className="h2tag">
                    World's First Frog Token With <span> 10% Extra</span> Buyer
                    Rewards !!
                  </h2>
                </div>
              </div>
              <div
                className="col-xl-7"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img
                  src={require("../assets/images/banimg.png")}
                  alt="img"
                  className="img-fluid mb-3 banimg"
                />
              </div>
            </div>

            <div className="row">
              <div
                className="col-xl-5"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="chain_details_div">
                  <h2 className="h2tag">Ethereum Chain Token</h2>
                  <p className="addr">
                    Contract Address : https://frogy.io/i/g9pnqp/
                    <img
                      src={require("../assets/images/copy.png")}
                      alt="img"
                      className="img-fluid ms-2"
                    />
                  </p>
                  <button class="primary_btn">
                    Buy On Uniswap and Get 10% Extra Tokens !!
                  </button>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h4.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h5.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h6.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h7.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h8.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                  </ul>

                  <div className="exchanges">
                    <h2 className="h2tag">Exchanges</h2>
                    <div className="row">
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>okx</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e2.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>binance</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e3.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>kucoin</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e4.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>huobi</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e5.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>bybit</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e6.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>crypto.com</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e7.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>bitget</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e8.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>mexc</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e9.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>bingx japan</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-5 ms-xl-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="chain_details_div">
                  <h2 className="h2tag">BNB Chain Token</h2>
                  <p className="addr">
                    Contract Address : https://frogy.io/i/g9pnqp/
                    <img
                      src={require("../assets/images/copy.png")}
                      alt="img"
                      className="img-fluid ms-2"
                    />
                  </p>
                  <button class="primary_btn">
                    Buy On PanCakeSwap and Get 10% Extra Tokens !!
                    <span className="round" />
                  </button>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h4.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h5.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h6.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h7.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={require("../assets/images/h9.png")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                  </ul>

                  <div className="exchanges">
                    <h2 className="h2tag">Exchanges</h2>
                    <div className="row">
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>okx</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e2.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>binance</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e3.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>kucoin</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e4.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>huobi</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e5.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>bybit</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e6.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>crypto.com</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e7.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>bitget</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e8.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>mexc</p>
                        </a>
                      </div>
                      <div className="col-md-4">
                        <a href="#" className="box">
                          <img
                            src={require("../assets/images/e9.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <p>bingx japan</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="story_sec" name="about">
        <div className="container">
          <div className="text-center">
            <h2 className="h2tag">About</h2>
          </div>
          <div className="row">
            <div
              className="col-md-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <p className="para">
                After living in dark, ignored, marshy lands for so many years,
                finally the frogs time has come. Till recently, dogs were ruling
                the meme kingdom.{" "}
              </p>
              <p className="para">
                But now, everything is going to change. Inspired from the PEPE
                revolution, now every frog aspires to reach to the moon.
              </p>
              <p className="para">
                Our FROGY, the cutest one in the town has his sight fixed on
                that ultimate goal.
              </p>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="imgbox">
                <img
                  src={require("../assets/images/about.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div
              className="col-md-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <p className="para">
                To achieve that ultimate goal, FROGY has devised a clever plan.
                With improved Tokenomics, 1st in industry Buyer Rewards, FROGY
                is now ready to rule the meme world.
              </p>
              <p className="para">
                With stealth fairlaunch, LP burnt, Dev tokens locked, and
                contract renounced, FROGY is a coin for all common people,
                forever.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="anthem_sec" name="anthem">
        <div className="container">
          <div data-aos="fade-up" data-aos-duration="1000">
            <h2 className="h2tag">National Anthem</h2>
            <p className="para">
              After that the Holy Spirit ordered me to recite a song, and
              declared the song as the national anthem of MemeNation. The Holy
              Spirit ordered me that i should ensure that every citizen of the
              MemeNation should daily recite this national anthem. Whoever will
              not recite this anthem daily, will be debarred from enjoying the
              taste of the Holy Shit. After this, the Holy Spirit vanished.
            </p>
          </div>
          <div
            className="head"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <p className="bold">
              Everyone should recite the following anthem daily.
            </p>
            <div className="poops">
              <p>Pop Pop</p>
              <p>Poop Poop</p>
              <p>Pop Pop</p>
              <p>Poop Poop</p>
              <p>Pop Pop</p>
              <p>Poop Poop</p>
              <p>DogePoop</p>
            </div>

            <div className="booo">
              <h5 className="bold">Boooooooooooooooooooooooooooooooooo</h5>
            </div>
          </div>
        </div>
      </section> */}

      <section className="how_sec" name="how">
        <div className="container">
          <h2 className="h2tag text-center">How To Buy</h2>
          <div
            className="box"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <img
              src={require("../assets/images/w1.png")}
              alt="img"
              className="img-fluid"
            />
            <div>
              <p className="bold">Create a Wallet</p>
              <p className="para">
                Download metamask or your wallet of choice from the app store or
                google play store for free. Desktop users, download the google
                chrome extension by going to metamask.io.
              </p>
            </div>
          </div>
          <div
            className="box"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <img
              src={require("../assets/images/w2.png")}
              alt="img"
              className="img-fluid"
            />
            <div>
              <p className="bold">Get Some ETH or BNB</p>
              <p className="para">
                Have ETH or BNB in your wallet to switch to $FROGY. If you don’t
                have any ETH or BNB, you can buy directly on metamask, transfer
                from another wallet, or buy on another exchange and send it to
                your wallet.
              </p>
            </div>
          </div>
          <div
            className="box"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <img
              src={require("../assets/images/w3.png")}
              alt="img"
              className="img-fluid"
            />
            <div>
              <p className="bold">Go to Uniswap or PanCakeSwap </p>
              <p className="para">
                Connect to Uniswap. Go to app.uniswap.org in google chrome or on
                the browser inside your Metamask app. Connect your wallet. Paste
                the $FROGY token address into Uniswap, select FROGY, and
                confirm. When Metamask prompts you for a wallet signature, sign.
              </p>
            </div>
          </div>
          <div
            className="box"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="700"
          >
            <img
              src={require("../assets/images/w4.png")}
              alt="img"
              className="img-fluid"
            />
            <div>
              <p className="bold">Switch ETH for $FROGY</p>
              <p className="para">
                We have ZERO taxes so you don’t need to worry about buying with
                a specific slippage, although you may need to use slippage
                during times of market volatility.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="economic_sec" name="economics">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 className="h2tag">Tokenomics</h2>
            </div>

            <div
              className="col-md-6 col-lg-5"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <h5>Transaction Rewards and Taxes</h5>
              <p className="para mb-2">
                Buy Rewards - 10%{" "}
                <span>
                  <Link
                    to="faq"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    ( see FAQ )
                  </Link>
                </span>{" "}
              </p>
              <p className="para">
                Sell Tax - 10%
                <span>
                  <Link
                    to="faq"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    ( see FAQ )
                  </Link>
                </span>{" "}
              </p>

              <ul>
                <li>
                  <p className="para">Liquidity - 60% ( 600,000,000,000 )</p>
                  <span>
                    LP Locked For 100 Years
                    <img
                      src={require("../assets/images/lock.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </span>
                </li>
                <li>
                  <p className="para">Buy Rewards - 7% ( 70,000,000,000 )</p>
                </li>
                <li>
                  <p className="para">CEX Listings - 10% ( 100,000,000,000 )</p>
                </li>
                <li>
                  <p className="para">Marketing - 3% ( 30,000,000,000 )</p>
                </li>
                <li>
                  <p className="para">NFT Rewards - 10% ( 100,000,000,000 )</p>
                  <span>
                    Locked For 1 Year
                    <img
                      src={require("../assets/images/lock.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </span>
                </li>
                <li>
                  <p className="para">Dev - 10% ( 42,000,000,000,000 )</p>
                  <span>
                    Locked For 3 Years
                    <img
                      src={require("../assets/images/lock.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </span>
                </li>
              </ul>
            </div>
            <div
              className="col-md-6 col-lg-7 text-center m-auto"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <h3>
                <span>Total Supply :</span> 420,000,000,000,000
              </h3>
              <div className="boxes">
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <img
                      src={require("../assets/images/t1.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <img
                      src={require("../assets/images/t2.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <img
                      src={require("../assets/images/t3.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <img
                      src={require("../assets/images/t4.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <img
                      src={require("../assets/images/t5.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <img
                      src={require("../assets/images/t6.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="poopmap" name="map">
        <div className="container">
          <h2 className="h2tag text-center">RoadMap</h2>
          <img
            src={require("../assets/images/roadmap.png")}
            alt="img"
            className="img-fluid map_img"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
          <div className="row mob_view">
            <div
              className="col-md-6 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="box">
                <p>Phase I -</p>
                <ul>
                  <li>Stealth FairLaunch</li>
                  <li>CoinGecko / CoinMarketCap Listings</li>
                  <li>1000+ Holders</li>
                  <li>Get FROGY trending on Twitter</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-6 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="box">
                <p>Phase II -</p>
                <ul>
                  <li>10000+ Holders</li>
                  <li>CEX Listings</li>
                  <li>Get FROGY trending on all Social Media</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-6 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="box">
                <p>Phase III -</p>
                <ul>
                  <li>100000+ Holders</li>
                  <li>T1 Exchange Listings</li>
                  <li>FROGY NFTs</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-6 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="box">
                <p>Phase IV -</p>
                <ul>
                  <li>1 Million + Holders</li>
                  <li>10 Billion + Marketcap</li>
                </ul>
              </div>
            </div>
          </div>
          <img
            src={require("../assets/images/roadmap_img.png")}
            alt="img"
            className="img-fluid image"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
        </div>
      </section>

      <section
        className="faq_sec"
        name="faq"
        data-aos="fade-up"
        data-aos-duration="2500"
      >
        <div className="container">
          <h2 className="h2tag text-center">FAQ</h2>
          {/* {faq?.map((item, index) => ( */}
          <div class="accordion" id="accordionExample">
            <div
              class="accordion-item collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <h2 class="accordion-header" id="#headingOne">
                <button class="accordion-button" type="button">
                  How To Buy?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="para">
                    Create a Wallet. Download metamask or your wallet of choice
                    from the app store or google play store for free. Desktop
                    users, download the google chrome extension by going to
                    metamask.io.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="accordionExample">
            <div
              class="accordion-item collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <h2 class="accordion-header" id="#headingTwo">
                <button class="accordion-button" type="button">
                  How To Buy?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="para">
                    Get Some ETH or BNB. Have ETH or BNB in your wallet to
                    switch to $FROGY. If you don’t have any ETH or BNB, you can
                    buy directly on metamask, transfer from another wallet, or
                    buy on another exchange and send it to your wallet.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ))} */}
        </div>
      </section>

      <Footer />
    </div>
  );
}
